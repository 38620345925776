import React from 'react';

const TestimonialImage01 = '/img/testimonial-01.jpg'
const TestimonialImage02 = '/img/testimonial-02.jpg'
const TestimonialImage03 = '/img/testimonial-03.jpg'

export default function CallToAction() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-6 md:pb-10">
            <h2 className="h2 mb-4">Ready to get started?</h2>
            <p className="text-xl text-gray-400">
              While you ponder, others are getting discovered
            </p>
          </div>

          <div className="max-w-xs mx-auto sm:max-w-none sm:justify-center">
              <div data-aos="fade-up" data-aos-delay="400" className="flex justify-center">
                <a 
                  onClick={() => {
                    window.location.href = "/sign-in"
                  }}
                  href="#"
                  className="btn text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-2/3 sm:mb-0 font-bold text-2xl"
                >
                    Start for free!
                </a>
              </div>
            </div>

        </div>
      </div>
    </section>
  )
}

import './Landing.css';

import Header from './components/./marketing/header'

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Episode } from './components/episode-selection-card';
import EpisodeWorkspaceComponent from './components/EpisodeWorkspaceComponent';
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

export default function EpisodeWorkspace({ session }: { session: Session}) {
  const params = new URLSearchParams(useLocation().search)
  const alreadyLoadedRef = useRef<boolean>(false);

  const [episode, setEpisode] = useState<Episode | null>(null);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);

  useEffect(() => {
    if (alreadyLoadedRef.current) {
      return;
    }
    const episodeId = params.get('episodeId');
    console.log("Loading episode: ", episodeId);
    alreadyLoadedRef.current = true;

    fetch(`/api/episode-detail/${episodeId}`, { method: 'GET' })
        .then(response => response.json())
        .then(data => {
            console.log("Episode workspace data: ", data);
            setEpisode(data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
  }, [params]);

  useEffect(() => {
    if (!session.name) {
      window.location.href = '/sign-in';
    }
  }, []);

  return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header 
            username={session.name}
        />

        <PageIllustration />

        { episode && 
        <EpisodeWorkspaceComponent
            episode={episode}
        /> }

      <Footer />
    </div>
  </div>
    );
}

import '../Landing.css';

import Header from '../components/./marketing/header'

import PageIllustration from '../components/marketing/page-illustration'
import Footer from '../components/./marketing/footer'
import { Session } from '../session';


import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

function AdminTool({ title, description, url }: { title: string, description: string, url: string }) {
    return (
        <div className="bg-gray-800 rounded-lg shadow-lg">
            <div className="p-6">
                <h3 className="text-xl font-semibold text-white">{title}</h3>
                <p className="mt-4 text-gray-300">
                    {description}
                </p>
                <a href={url} className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-slate-500 hover:bg-slate-600">
                    {title}
                </a>
            </div>
        </div>
    );
}

export default function AdminIndex({ session }: { session: Session}) {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);


    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-8xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-20">

            {/* Page header */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                <h1 className="h1">Admin Index</h1>
                <h2 className="h2 text-slate-500 pt-4">Tools for administration</h2>
            </div>

            {/* Blog posts. Arrange into a 2 by n grid */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                {/* List out admin tools: 1) create blog post, 2) Generate blog post */}
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
                    <AdminTool
                        title="Create Blog Post"
                        description="Create a new blog post."
                        url="/admin/create-blog-post"
                    />
                    <AdminTool
                        title="Generate SEO Blog Post"
                        description="Generate a new blog post."
                        url="/admin/generate-blog-post"
                    />
                    <AdminTool
                        title="Apothecary"
                        description="Mix potions."
                        url="/admin/apothecary"
                    />
                    <AdminTool
                        title="Login as User"
                        description="Login as any user."
                        url="/admin/login-as-user"
                    />

            </div>
            </div>

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}

import './Landing.css';

import Header from './components/./marketing/header'

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';


import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

type BlogPostMetadata = {
    title: string;
    description: string;
    url: string;
    image: string;
    date: string;
}

function BlogPostCard({ post }: { post: BlogPostMetadata }) {
    // Should show the image with rounded corners and a drop shadow
    // Show the title large, description below. Show the date. Everything should link to the blog post when clicked
    return (
        <div className="max-w-6xl mx-auto pb-6 md:pb-10">
            <div className="flex flex-col justify-center">
                <div className="w-full">
                    <div className="w-full overflow-hidden">
                        <a href={`/blog/post/${post.url}`}>
                            <img src={post.image} alt={post.description} className="w-full rounded-xl" style={{ maxHeight: '300px', objectFit: 'cover' }} />
                        </a>
                    </div>
                </div>
                <div className="mt-4 px-4">
                    <h2 className="h2 leading-6 font-medium text-gray-300">
                        <a href={`/blog/post/${post.url}`} className="hover:underline">
                            <p className="leading-8" >{post.title}</p>
                        </a>
                    </h2>
                    <h4 className="h4 text-gray-500 mt-4">
                        <a href={`/blog/post/${post.url}`} className="hover:underline">
                            {post.description}
                        </a>
                    </h4>
                    <div className="mt-2 text-sm text-gray-600">
                        <time dateTime="2020-03-16">{post.date}</time>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default function BlogIndex({ session }: { session: Session}) {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);


    const [blogPosts, setBlogPosts] = useState<BlogPostMetadata[]>([]);

    useEffect(() => {
        fetch('/api/blog-posts-metadata')
        .then(response => response.json())
        .then(json => {
            console.log(json);
            setBlogPosts(json);
            console.log("Done fetching blog posts.");
        });
    }, []);


    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-8xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-20">

            {/* Page header */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                <h1 className="h1">Addendum AI Blog</h1>
                <h2 className="h2 text-slate-500 pt-4">Learn more about podcast growth, marketing, and SEO!</h2>
            </div>

            {/* Blog posts. Arrange into a 2 by n grid */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
                    {blogPosts.map((post: BlogPostMetadata) => (
                        <BlogPostCard
                            key={post.url}
                            post={post}
                        />
                    ))}
                </div>
            </div>

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}

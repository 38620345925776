import React from 'react';

export const metadata = {
    title: 'Sign In - Open PRO',
    description: 'Page description',
  }
  
  export default function SignIn() {
    return (
      <section className="relative">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
  
            {/* Page header */}
            <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
              <h1 className="h1">Strap in to power up your podcast marketing with AI.</h1>
            </div>
  
            {/* Form */}
            <div className="max-w-sm mx-auto">
                <div className="flex flex-wrap -mx-3">
                  <div className="w-full px-3">
                    <button className="btn px-0 text-white bg-red-600 hover:bg-red-700 w-full relative flex items-center"
                        onClick={() => {
                            const url = new URL(window.location.href);
                            const postRedirect = url.searchParams.get('post_redirect') || "";

                            if (postRedirect) {
                                window.location.href = '/google?post_redirect=' + postRedirect;
                            } else {
                                window.location.href = '/google';
                            }
                        }}
                    >
                      <svg className="w-4 h-4 fill-current text-white opacity-75 shrink-0 mx-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" />
                      </svg>
                      <span className="h-6 flex items-center border-r border-white border-opacity-25 mr-4" aria-hidden="true"></span>
                      <span className="flex-auto pl-16 pr-8 -ml-16">Sign in with Google</span>
                    </button>
                  </div>
                </div>
              <div className="text-gray-400 text-center mt-6">
                Don’t have an account? <a href="/google" className="text-purple-600 hover:text-gray-200 transition duration-150 ease-in-out">Sign up</a>
              </div>

              </div>
  
          </div>
        </div>
      </section>
    )
  }
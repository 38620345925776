import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Landing from './Landing';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga4';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SignInPage from './SignInPage';
import Episodes from './Episodes';
import EpisodeWorkspace from './EpisodeWorkspace';
import { getSession } from './session.ts';

import Hotjar from '@hotjar/browser';
import Pricing from "./Pricing";
import Contact from "./Contact";
import ServerError from "./ServerError";
import Account from "./Account";
import Message from "./Message";
import BlogIndex from "./BlogIndex";
import AdminIndex from "./admin/AdminIndex";
import BlogPost from "./BlogPost";
import SubdomainIndex from "./SubdomainIndex";
import SubdomainPost from "./SubdomainPost";
import SubdomainDirectory from "./SubdomainDirectory";
import AdminCreateBlogPost from "./admin/AdminCreateBlogPost";
import AdminLoginAsUser from "./admin/AdminLoginAsUser";
const siteId = 3742247;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

let session = await getSession();
if (!session) session = { user: null };


const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

console.log(session);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing 
      session={session}
      />,
    errorElement: <h1>404 Error: Not found</h1>
  },
  {
    path: "/sign-in",
    element: <SignInPage 
      session={session}
      />,
  },
  { 
    path: "/episodes",
    element: <Episodes 
      session={session}
      />,
  },
  {
    path: "/episode-workspace",
    element: <EpisodeWorkspace
      session={session}
      />,
  },
  {
    path: "/blog/index",
    element: <BlogIndex
        session={session}
    />,
  },
  {
    path: "/blog/post/:id",
    element: <BlogPost
        session={session}
    />,
  },
  {
    path: "/podcast_directory",
    element: <SubdomainDirectory
        session={session}
    />,
  },
  {
    path: "/podcast/:subdomain/index",
    element: <SubdomainIndex
        session={session}
    />,
  },
  {
    path: "/podcast/:subdomain/post/:id",
    element: <SubdomainPost
        session={session}
    />,
  },
  {
    path: "/pricing",
    element: <Pricing
        session={session}
    />,
  },
  {
    path: "/account",
    element: <Account session={session} />,
  },
  {
    path: "/contact",
    element: <Contact session={session} />,
  },
  {
    path: "/message",
    element: <Message session={session} />,
  },
  {
    path: "/admin/index",
    element: <AdminIndex
        session={session}
    />,
  },
  {
    path: "/admin/create-blog-post",
    element: <AdminCreateBlogPost
        session={session}
    />,
  },
  {
    path: "/admin/login-as-user",
    element: <AdminLoginAsUser
        session={session}
    />,
  },
  {
    path: "/error",
    element: <ServerError session={session} />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={3}>
    <RouterProvider router={router} />
    </SnackbarProvider>
  </React.StrictMode>
);

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useState} from "react";
import MDEditor from "@uiw/react-md-editor";
import {enqueueSnackbar} from "notistack";
import PublishModal from "./publish-modal";

const removeIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
    </svg>
);

const editIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
    </svg>
);

const chevronDoubleRightIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
    </svg>
)


export default function ArticleEditor(
    {
        article,
        index,
        removeArticle,
        handleChangeArticleBody,
        handleEditArticleTitleClick,
        handleBlurArticleTitle,
        updateArticleInState,
        streamArticleBody
    }
    : {
    article: Article,
    index: number,
    removeArticle: (index: number) => void,
    handleChangeArticleBody: (newBody: string, article: Article) => void,
    handleEditArticleTitleClick: (article: Article) => void,
    handleBlurArticleTitle: (article: Article) => void,
    updateArticleInState: (article: Article, updateProperties: Partial<Article>) => void,
    streamArticleBody: (title: string, id: string, setIsChevronRotated: (arg0: boolean) => void) => void
}) {

    const [isChevronRotated, setIsChevronRotated] = useState(false);
    const editArticleTitleClick = () => {
        handleEditArticleTitleClick(article);
    }

    const blurArticleTitle = () => {
        handleBlurArticleTitle(article);
    }

    const changeArticleBody = (newBody: string) => {
        handleChangeArticleBody(newBody, article);
    }

    return (
        <>
            <div className="p-4 bg-backgroundCruip rounded-lg mt-5 flex flex-row justify-between">
                <div className="flex-grow">
                    <p className="mt-2 text-gray-400">Proposed Article</p>
                    { article.isEditingTitle ? (
                        <textarea
                            className="w-full leading-normal rounded-lg text-gray-100 mt-1 mb-2 text-2xl p-0 font-bold bg-gray-800 border-b-0 border-t-0 border-r-0 border-l-0 border-gray-900 focus:border-transparent focus:ring-0 focus:outline-none"
                            value={article.title}
                            onChange={(e) =>
                                updateArticleInState(article,
                                    { title: e.target.value || " " })
                            }
                            rows={3}
                            onBlur={() => blurArticleTitle()}
                        />
                    ) : (
                        <h2 className="h4 mt-1 mb-2 text-gray-100 font-bold " key={index}>{ article.title }</h2>)
                    }
                </div>
                <div className="flex flex-col justify-between flex-shrink">
                    <div><button
                        onClick={() => { removeArticle(index) }}
                    >
                        { removeIcon }
                    </button></div>
                    <div><button
                        onClick={() => editArticleTitleClick()}
                    >
                        { editIcon }
                    </button></div>
                    <div><button
                        onClick={() => { streamArticleBody(article.title, article.id, setIsChevronRotated) }}
                        style={{ transform: isChevronRotated ? 'rotate(90deg)' : 'none' }}
                    >
                        { chevronDoubleRightIcon }
                    </button></div>


                </div>
            </div>

            { article.body && (
                <>
                    <div className="my-5" data-color-mode="dark">
                        <MDEditor height={1000} value={article.body} onChange={(e: any) => changeArticleBody(e)} />
                    </div>
                    <div className="flex flex-row justify-end">
                        <PublishModal article={article} />
                        {/*<button
                            onClick={() => { publish(article) }}
                            className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Publish
                        </button>*/}
                    </div>
                </>
            )}
        </>
    )
}
export interface Session {
    name: string;
    sub: string,
    id_token: string,
    email: string,
    picture: string,
}

export async function getSession(): Promise<Session|undefined> {
    /*const sessionCookie = window.localStorage.getItem('session-cookie');
    if (sessionCookie) {
        const sessionCookieJson = JSON.parse(sessionCookie);
        return sessionCookieJson;
    }*/

    return fetch('/api/session-cookie')
    .then((res) => {
        if (res.status === 200) return res.json();
        throw new Error('Error retrieving session cookie.');
    })
    .then((payload) => {
        if (payload.message === 'not_logged_in') return;
        window.localStorage.setItem('session-cookie', JSON.stringify(payload.session));
        return payload.session;
    })
    .catch((err) => {
        console.log(err);
    });
}

export function logout() {
    fetch('/api/logout')
    .then((res) => {
        if (res.status === 200) {
            window.localStorage.removeItem('session-cookie');
            window.location.href = '/';
        }
    })
    .catch((err) => {
        console.log(err);
    });
}
import './Landing.css';

import Header from './components/./marketing/header'
import EpisodesComponent from './components/episodes-component';

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';
import { useEffect } from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

export default function Episodes({ session }: { session: Session}) {

  useEffect(() => {
    if (!session.name) {
      window.location.href = '/sign-in';
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({page: location.pathname + location.search});
  }, [location]);

  return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header 
        username={session.name}
        />

      <PageIllustration />

      <EpisodesComponent 
        name={session.name}
      />

      <Footer />
    </div>
  </div>
    );
}

import { ArticleTitleSelector } from './ArticleTitleSelector';
import { Episode } from './episode-selection-card';
import ExpandableDeliverable from './expandable-deliverable';
import {SEOKeywords} from "./SEOKeywords";
import {useEffect, useState} from "react";


function capitalizeFirstLetter(str: string) {
    if(str && str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
        return str;
    }
}

// Usage example


const Disclaimer= () => {

    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [account, setAccount] = useState<any>({});
    useEffect(() => {
        fetch('/api/account')
        .then(response => response.json())
        .then(json => {
          console.log(json);
          if (!json.plan) {
                json.plan = "not subscribed_noplan";
                json.subscribed = false;
          }
          setAccount(json);
          console.log("Done fetching account.");
          setHasLoaded(true);
        });
    }, []);

    return (
        <>
        {hasLoaded && !account.subscribed && (
        <div className="mb-10 mx-auto p-2 md:max-w-2xl mx-w-lg text-center border-2 border-purple-600 bg-backgroundCruip text-gray-500 rounded-lg shadow-md">
        <h2 className="font-semibold text-gray-300 text-xl mb-2">Free tier note</h2>
        <p className="text-sm">
            The free tier is limited to processing only the first 10 minutes of episodes.
            This means your transcription, summary, table of contents, and articles will only contain content from the first 10 minutes of your episode.
            To access full episode processing, consider upgrading your plan.
        </p>
        </div>)}

        {hasLoaded && account.subscribed && account.plan.split('_')[0] === 'basic' && (
        <div className="mb-10 mx-auto p-2 md:max-w-2xl mx-w-lg text-center border-2 border-purple-600 bg-backgroundCruip text-gray-500 rounded-lg shadow-md">
        <h2 className="font-semibold text-gray-300 text-xl mb-2">Plan tier: Basic</h2>
        <p className="text-sm">
            Your plan gives you 30 minutes of audio processing per episode. This means your transcription, summary, table of contents, and articles
            will only contain content from the first 30 minutes of your episode.
            Upgrading to the Pro plan will give you 60 minutes of processing per episode.
        </p></div>)}

        {hasLoaded && account.subscribed && account.plan.split('_')[0] === 'pro' && (
        <div className="mb-10 mx-auto p-2 md:max-w-2xl mx-w-lg text-center border-2 border-purple-600 bg-backgroundCruip text-gray-500 rounded-lg shadow-md">
        <h2 className="font-semibold text-gray-300 text-xl mb-2">Plan tier: Pro</h2>
        <p className="text-sm">
            Your plan gives you 60 minutes of audio processing per episode. This means your transcription, summary, table of contents, and articles
            will only contain content from the first 60 minutes of your episode.
            Upgrading to the Ultra plan will give you unlimited processing per episode.
        </p></div>)}
        </>
    );
};
export default function EpisodeWorkspaceComponent({ episode }: { episode: Episode}) {

  const deleteThisEpisode = () => {
    // 1) Call the episode deletion endpoint
    fetch(`/api/delete-episode/${episode.id}`, { method: 'GET' })
      .then(response => response.json())
      .then(data => {
        console.log("Response from episode deletion: ", data);
        // 2) Redirect to the episodes page
        window.location.href = "/episodes";
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-20 md:pb-20"></div>

            {/* Page header */}
            <Disclaimer />

            <div className="max-w-lg md:max-w-4xl mx-auto pb-6 md:pb-10 overflow-hidden">
                <h1 className="h2">Episode: { episode.title }</h1>
            </div>
            <div className="max-w-lg md:max-w-4xl mx-auto pb-6 md:pb-10">
                {/*<h2 className="h4 text-gray-500">Audio file name: { episode.title != episode.audioFilename && episode.audioFilename } </h2>*/}
                <h2 className="h4 text-gray-500">Uploaded date: { episode.uploadDate } </h2>
              </div>

            {/* Delete button */}
            <div className="max-w-lg md:max-w-4xl mx-auto flex justify-between">
                <button 
                  className="btn text-gray-400 hover:text-gray-100 rounded-lg border-2 border-purple-600 hover:bg-purple-600"
                  onClick={() => window.location.href = "/episodes"}
                
                  >
                    Back to episodes
                </button>
                <button 
                  className="btn text-gray-500 hover:text-red-700 rounded-lg border-2 border-purple-600 border-opacity-25 hover:bg-purple-600"
                  onClick={deleteThisEpisode}
                  >
                    Delete Episode
                </button>
            </div>

            <ExpandableDeliverable
              title="Transcription"
              streaming_endpoint={`/api/subscribe_transcription/${episode.id}`}
            />

          {/* 01/16/24 - Commenting these out because our platform isn't about content / social, it's about SEO
          and we don't want to confuse people with these features. */}
          {/*
            <ExpandableDeliverable 
              title="Summary"
              streaming_endpoint={`/api/subscribe_summary/${episode.id}`}
              />

            <ExpandableDeliverable 
              title="Table of Contents"
              streaming_endpoint={`/api/subscribe_toc/${episode.id}`}
              />
              */}

            <SEOKeywords episodeId={episode.id} />

            <ArticleTitleSelector 
              episodeId={episode.id}
              />
            
        </div>
    </section>
  )
}

import React, {useEffect, useState} from 'react';
import "../additional-styles/loading-spinner-1.css";
import {enqueueSnackbar, useSnackbar} from 'notistack';

export default function ClaimSubdomain() {
    const [loading, setLoading] = useState(false);
    const [subdomainInput, setSubdomainInput] = useState("your-subdomain");
    const [message, setMessage] = useState("");

    const [alreadyRegistered, setAlreadyRegistered] = useState(false);

    const [registeredSubdomain, setRegisteredSubdomain] = useState("");

    useEffect(() => {
        // Get subdomain from account - /api/get-subdomain-from-account
        fetch('/api/get-subdomain-from-account')
        .then(response => response.json())
        .then(json => {
          console.log(json);
          if (json.success) {
              setSubdomainInput(json.subdomain);
              setRegisteredSubdomain(json.subdomain);
              setAlreadyRegistered(true);
          } else {
              console.log("Error getting subdomain from account:" + json.message);
          }
          console.log("Done fetching subdomain.");

        });
    }, []);

    const claimSubdomain = () => {
        setLoading(true);
        fetch(`/api/subdomain-claim/${subdomainInput}`, { method: 'GET' })
        .then(response => response.json())
        .then(json => {
            console.log(json);
            setLoading(false);
            if (json.success) {
                enqueueSnackbar("Subdomain claimed successfully!", { variant: 'success' });
                setAlreadyRegistered(true);
                setRegisteredSubdomain(subdomainInput);
            } else {
                enqueueSnackbar("Error claiming subdomain. Please try again.", { variant: 'error' });
            }
            if (json.message) {
                setMessage(json.message);
            }
        });
    };

    return (
        <div className="mt-12 max-w-3xl mx-auto shadow-md rounded-lg overflow-hidden">
            <div className="bg-purple-600 h-20 flex items-center justify-center">
                <h2 className="text-white text-lg font-semibold">
                    {alreadyRegistered ?
                        "Your subdomain is registered at https://addendum.ai/podcast/" + registeredSubdomain :
                        "Claim your Addendum.ai SEO blog subdomain"}
                </h2>
            </div>
            <div className="p-6 bg-backgroundCruip">
                <p className="text-gray-400 mt-2">
                    {alreadyRegistered ?
                        "You can change your subdomain at any time by entering a new name below." :
                        `Enter a name to claim your subdomain. Your SEO articles will be published to https://addendum.ai/podcast/${subdomainInput}` }
                </p>
                <p className="text-gray-600">

                </p>

                <div className="mt-4 flex justify-between items-center">
                    <input type="text"
                           className="mt-4 text-gray-500 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                           placeholder={subdomainInput}
                           value={subdomainInput}
                           onChange={(e) => {
                               setSubdomainInput(e.target.value)
                           }}
                    />
                    <button className="ml-2 mt-4 w-1/3 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-md px-4 py-2 transition-colors duration-200 transform"
                            onClick={() => claimSubdomain()}
                    >
                        Claim
                    </button>
                </div>
                <div className="mt-4">
                    {loading && (
                        <div className="flex justify-center">
                            <div className="loading-spinner-1"></div>
                        </div>
                    )}
                    {message && (
                        <p className="mt-4 text-gray-400">{message}</p>
                    )}
                </div>

            </div>
        </div>
    );
}

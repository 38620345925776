import React, { useState } from 'react';

// From https://loading.io/css/
import "../additional-styles/loading-spinner-1.css";
import { Episode } from './episode-selection-card';
import { useSnackbar} from 'notistack';

export default function UploadEpisodeCard({ setEpisodes } : { setEpisodes: React.Dispatch<React.SetStateAction<Episode[]>> }) {
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileChange = (e: any) => {
    if (e.target.files.length) {
      setFileName(e.target.files[0].name);
      setLoading(true);
      // Here you can add any other logic or AJAX call to upload the file
      // After the operation completes, you should set the loading state back to false
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      console.log("Attempting to upload file...");
      fetch('/api/upload', {
        method: 'POST',
        body: formData
      }).then(response => response.json())
        .then(json => {
            if (json.error) {
                console.error('Error:', json.error);
                enqueueSnackbar('Error uploading file: You are not signed in.', { variant: 'error' }); // Display error snackbar
                setLoading(false);
                return;
            }

            console.log("Done uploading file.");
            console.log(json);
            setLoading(false);

            const topic = json.topic;
            console.log("Adding loading episode...")
            setEpisodes((prevEpisodes: Episode[]) => [
              {
                id: json.topic,
                key: "0",
                title: e.target.files[0].name,
                audioFilename: e.target.files[0].name,

                uploadDate: "Uploading...",
                isProcessed: false,

                transcriptionComplete: false,
                transcription: "",

                summaryComplete: false,
                summary: "",

                tocComplete: false,
                tableOfContents: "",

                articlesGenerated: 0,
                articles: [],

                subscriptionTopic: topic,
              },
              ...prevEpisodes
            ]);
      })
        .catch((error) => {
            console.error('Error:', error);
            enqueueSnackbar('Error uploading file. Please try again.', { variant: 'error' }); // Display error snackbar
            setLoading(false);
        });
    }
  };

  return (
    <div className="mt-12 max-w-sm mx-auto shadow-md rounded-lg overflow-hidden transition-transform duration-500 transform hover:scale-[1.25]">
        <div className="bg-purple-600 h-20 flex items-center justify-center">
          <h2 className="text-white text-lg font-semibold">Upload New Episode</h2>
        </div>
      <div className="p-6 bg-backgroundCruip">
          <p className="text-gray-400 mt-2">
              Choose your audio file to upload a new episode.
          </p>
          <div className="mt-4 flex justify-between items-center"> 
              <span className="text-gray-600 truncate">{fileName}</span>
              <label className={`cursor-pointer ${loading ? 'opacity-50 pointer-events-none' : 'bg-purple-600 hover:bg-purple-700'} text-white text-center rounded-md px-4 py-2 transition-colors duration-200 transform`}>
                  {loading ? <div className="lds-ripple"><div></div><div></div></div> : 'Upload File'}
                  <form>
                    <input type="file" className="hidden" accept=".mp3,.wav,.mp4" onChange={handleFileChange} disabled={loading} />
                  </form>
              </label>
          </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';

import UploadEpisodeCard from './upload-episode-card'
import EpisodeSelectionCard, { Episode } from './episode-selection-card';
import UploadEpisodeViaRssCard from "./upload-episode-via-rss-card";

export const metadata = {
    title: 'Episodes Selection and Upload',
    description: 'Page description',
  }
  
export default function EpisodesComponent(props: { name: string }) {
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [usingRss, setUsingRss] = useState<boolean>(true);

  const hasFetchedEpisodesRef = useRef<boolean>(false);

  useEffect(() => {
    if (hasFetchedEpisodesRef.current) return;
    hasFetchedEpisodesRef.current = true;

    fetch('/api/episodes')
    .then(response => response.json())
    .then(json => {
      console.log(json); 
      setEpisodes(json);
      console.log("Done fetching episodes.");
    });
  }, []);

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Page header */} 
          <div className="max-w-3xl mx-auto pb-6 md:pb-10">
            <h1 className="h1">Welcome back, { props.name }</h1>
            <h3 className="h4 text-slate-500 pt-4">Upload a new episode or select an existing one to get started.</h3>
          </div>

          {/* Upload episode card */}
          {!usingRss && <>
            <UploadEpisodeCard
              setEpisodes={setEpisodes}
              />
            <div className="max-w-3xl mx-auto overflow-hidden text-center">
              <p className="text-gray-600 mt-2">
                Have an RSS feed? <a href="#" onClick={() => {setUsingRss(true)}} className="text-purple-600 hover:text-purple-700">Click here</a> to use it instead.
              </p>
            </div>
          </> }

          {usingRss && <>
            <UploadEpisodeViaRssCard
                setEpisodes={setEpisodes}
            />
            <div className="max-w-3xl mx-auto overflow-hidden">
              <p className="text-gray-600 mt-2">
                Don't have an RSS feed? <a href="#" onClick={() => {setUsingRss(false)}} className="text-purple-600 hover:text-purple-700">Click here</a> to use .mp3 upload.
              </p>
            </div>
          </> }

          <div className="max-w-3xl mx-auto pb-2 pt-20 md:pt-20">
            <h1 className="h1">Your episodes</h1>
          </div>

          {/* For each episode, create an EpisodeSelectionCard */}
          {episodes.map((episode: any) => (
            <EpisodeSelectionCard 
                key={episode.key}
                episode={episode} 
              />
          ))}

          {episodes.length === 0 && 
          <div className="max-w-3xl mx-auto pb-2 pt-4">
            <h1 className="h4 text-gray-500">No episodes yet</h1>
          </div>}


        </div>
      </div>
    </section>
  )
}
import {useCallback, useEffect, useRef, useState} from 'react';
import MDEditor, { selectWord } from "@uiw/react-md-editor";
import { enqueueSnackbar } from "notistack";

// From https://loading.io/css/
import "../additional-styles/loading-spinner-1.css";
import ArticleEditor from "./article-editor";


export function ArticleTitleSelector(props: { episodeId: string }) {
  const [progressUpdates, setProgressUpdates] = useState<string[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);

  const isAlreadySubscribedRef = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [triggerSaveArticles, setTriggerSaveArticles] = useState<number>(10); // Holy shit I can't believe I have to do this
  // but apparently EventSource updates state asynchronously in a way I can't capture

  const hasAlreadyLoadedArticlesRef = useRef(false);

  const removeArticle = (index: number) => {
    const article = articles[index];
    setArticles(prevArticles => [...prevArticles.slice(0, index), ...prevArticles.slice(index + 1)]);
    deleteArticle(article.id)
        .then(res => res.json())
        .then(data => {
          if (data.error) {
            console.log("Error deleting article: ", data.error);
          }
        })
  }

  const collectArticleBodyChunk = (chunk: string, id: string) => {
    setArticles(prevArticles => {
      let accumulator = [];
      for (const prevArticle of prevArticles) {
        let article = prevArticle;
        if (id === prevArticle.id) {
          article = {
            title: prevArticle.title,
            body: prevArticle.body + chunk,
            id: id,
            isEditingTitle: false,
          }

          // Also, click the "preview" button on the markdown viewer (but make sure to only do it once)
          const threshold = 200;
          if (prevArticle.body.length <= threshold && article.body.length > threshold) {
            setTimeout(() => {
              clickAllMarkdownViewers()
            }, 1000);
          }
        }
        accumulator.push(article);
      }
      return accumulator;
    });
  }

  const streamArticleBody = (
      title: string,
      id: string,
      setIsChevronRotated: (arg0: boolean) => void = () => {}) => {
    setIsChevronRotated(true);

    const endpoint = `/api/stream_article_body/${props.episodeId}?title=${title}&article_id=${id}`;
    const stream = new EventSource(endpoint);

    stream.onmessage = event => {
      const payload = JSON.parse(event.data);

      if (payload.event_type === "message") {
        //console.log("streamArticleBody Message received: ", payload.message);
      }

      if (payload.event_type === "final_output") {
        //collectArticleTitleChunk(payload.message);
        // Why was this article title chunk? No idea
      }

      if (payload.event_type === "error") {
        enqueueSnackbar(payload.message, { variant: "error" });
      }

      if (payload.event_type === "stream_chunk") {
        const chunk = payload.message;
        collectArticleBodyChunk(chunk, id);
      }

      if (payload.event_type === "end_stream") {
        console.log("End stream recieved.");
        setIsLoading(false);
        stream.close();
        console.log("DONE STREAMING ARTICLE BODY");
        clickAllMarkdownViewers();

        setIsChevronRotated(false);
      }
    };

    stream.onerror = error => {
      console.error('EventSource failed:', error);
      stream.close();
    };
  }

  const stream5MoreArticleTitles = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    streamArticleTitles();
  }

  const streamArticleTitles = () => {
    const endpoint = `/api/generate_more_article_titles/${props.episodeId}`;
    const stream = new EventSource(endpoint);

    stream.onmessage = event => {
      const payload = JSON.parse(event.data);

      if (payload.event_type === "message") {
        console.log("Article Title stream => Message received: ", payload.message);
        setProgressUpdates(prevProgressUpdates => [...prevProgressUpdates, payload.message]);
      }

      if (payload.event_type === "error") {
        enqueueSnackbar(payload.message, { variant: "error" });
      }

      if (payload.event_type === "final_output") {
        console.log("Article Title stream => Final output received.");
        console.log("Article Title stream => Final output: ", payload.message);
      }

      if (payload.event_type === "stream_chunk") {
        console.log("Article Title stream => stream_chunk: ", payload.message);
      }

      if (payload.event_type === "end_stream") {
        console.log("Article Title stream => End stream received.");
        stream.close();

        // Load articles from the database
        loadArticles();


        //setTriggerSaveArticles(prevTriggerSaveArticles => prevTriggerSaveArticles + 1);
      }
    };

    stream.onerror = error => {
      console.error('EventSource failed:', error);
      stream.close();
    };
  }

  useEffect(() => {
    console.log("triggered save articles updated!!!!!", articles);
    saveArticles();
  }, [triggerSaveArticles]);

  const deleteArticle = (id: string) => {
    return fetch("/api/delete_article", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        episode_id: props.episodeId,
        article_id: id
      }),
    })
  }


  const saveArticles = (articlesToSave: Article[] | null = null) => {
    if (!articlesToSave) {
      articlesToSave = articles;
    }

    // Remove articles with empty titles
    articlesToSave = articlesToSave.filter(article => article.title !== "" || article.body !== "");

    console.log("Saving articles: ", articles);
    fetch('/api/save_articles', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        episode_id: props.episodeId,
        articles: articlesToSave
      }),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
  }

  const loadArticles = () => {
    return fetch(`/api/load_articles/${props.episodeId}`)
        .then(response => response.json())
        .then(data => {
          console.log('Successfully loaded articles:', data);
          if (data) {
            console.log("Setting articles");
            setIsLoading(false);
            setArticles(data);
            setTimeout(() => {
              clickAllMarkdownViewers();
            }, 1000);
          }
        })
  }

  const readyToStreamDefault = useRef<boolean>(false);


  useEffect(() => {
    if (isAlreadySubscribedRef.current) {
      return;
    }
    isAlreadySubscribedRef.current = true;
    loadArticles().then(() => {
      readyToStreamDefault.current = true;
    });
  }, []);

  useEffect(() => {
    console.log("Article useEffect triggered! kill me", articles.length);
    if (hasAlreadyLoadedArticlesRef.current || articles.length > 0) {
      return;
    }
    hasAlreadyLoadedArticlesRef.current = true;

    if (articles.length > 0) {
      return;
    }

    if (!readyToStreamDefault.current) {
      return;
    }
    readyToStreamDefault.current = false;

    console.log(" Just got finished loading articles for the first time");
    console.log("Articles length: ", articles.length);

    streamArticleTitles();
  }, [articles]);

  const updateArticleInState = (article: Article, updateProperties: Partial<Article>) => {
    setArticles(prevArticles => prevArticles.map(prevArticle =>
        prevArticle.id === article.id
            ? { ...prevArticle, ...updateProperties }
            : prevArticle
    ));
  };

  const saveArticlesTimeoutRefs = useRef<any>({});
  const delayedArticlePersist = (article: Article) => {
    // set a timeout for 5 seconds. If the user hasn't typed anything in 5 seconds, save the article.
    if (saveArticlesTimeoutRefs.current[article.id]) {
      clearTimeout(saveArticlesTimeoutRefs.current[article.id]);
    }
    saveArticlesTimeoutRefs.current[article.id] = setTimeout(() => {
      saveArticles([article]);
    }, 5000);
  }

  const handleChangeArticleBody = (newBody: string, article: Article) => {
    updateArticleInState(article, { body: newBody });
    delayedArticlePersist({...article, body: newBody});
  }
  const handleEditArticleTitleClick = (article: Article) => {
    updateArticleInState(article, { isEditingTitle: true });
  };

  const handleBlurArticleTitle = (article: Article) => {
    updateArticleInState(article, { isEditingTitle: false });
    delayedArticlePersist(article);
  }

  const clickAllMarkdownViewers = () => {
    // Use the data-name attribute to select all buttons
    const buttons = document.querySelectorAll('button[data-name="preview"]');
    buttons.forEach(button => {
      (button as HTMLElement).click();
    });
  };

  return (
    <div 
      className="mt-8 pb-6 max-w-lg md:max-w-4xl mx-auto overflow-hidden transition-transform duration-500 transform"
    >
    <div className="bg-purple-600 mb-6 rounded-lg p-6 flex flex-row justify-evenly">
      <div className="max-w-[80%]">
        <h2 className="text-white text-2xl font-semibold truncate">Proposed Articles</h2>
      </div>
        {isLoading &&
           <div className="lds-ripple"><div></div><div></div></div> }
    </div>

    {/* Middle Pane: Episode summary or loading message */}
    { progressUpdates.length > 0 && ( <div className="p-4 bg-backgroundCruip border-4 border-red-900 text-gray-400 rounded-lg mt-4 ">
      {progressUpdates.map((update, index) => {
        return (
          <p key={index}>{ update }</p>
        )
        })}
    </div> )}

    
    { articles.sort((a, b) => a.id > b.id ? 1 : -1).map((article, index) => {
        if (article.title === "") {
          return null;
        }
        return (
          <ArticleEditor
              key={article.id}
              article={article}
              index={index}
              removeArticle={removeArticle}
              handleChangeArticleBody={handleChangeArticleBody}
              handleEditArticleTitleClick={handleEditArticleTitleClick}
              handleBlurArticleTitle={handleBlurArticleTitle}
              updateArticleInState={updateArticleInState}
              streamArticleBody={streamArticleBody}
               />
      );
    })}

    <div 
      className={`border-purple-600 ${isLoading ? 'bg-gray-700' : 'hover:bg-purple-600'} mb-6 border-2 rounded-lg p-2 mt-6 flex flex-row justify-evenly`}
      onClick={stream5MoreArticleTitles}
    >
      <div className="max-w-[80%]">
        <h2 className="text-white text-xl font-semibold truncate">Generate 5 article titles</h2>
      </div>
    </div>
    
  </div>
  );
}

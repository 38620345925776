import './Landing.css';

import Header from './components/./marketing/header'

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';


import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);


export default function ServerError({ session }: { session: Session}) {

    const [error, setError] = useState<string>("");

    useEffect(() => {
        const url = new URL(window.location.href);
        const errorText = url.searchParams.get('error') || "";
        setError(errorText);
        ReactGA.send({error: errorText});
    }, []);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);

    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-20 md:pb-20">

            <section className="relative">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-32 pb-12 md:pt-20 md:pb-20">
                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                            <h2 className="h2 mb-16">Error</h2>
                            <p className="text-xl mb-16 text-red-700">
                                {error}
                            </p>
                        </div>


                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                            <p className="h2 text-xl text-gray-100">
                                If you need help, or to report an issue with Addendum.ai, please reach out to griffin@addendum.ai
                            </p>
                        </div>


                    </div>
                </div>
            </section>

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}

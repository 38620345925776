import './Landing.css';

import Header from './components/./marketing/header'

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';


import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

// Styled components for the toggle switch
const ToggleLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 90px;
  height: 50px; // 34px
  background: #1f6feb;
  border-radius: 9999px; // Fully rounded ends for the toggle background
  position: relative;
  transition: background-color 0.2s;
`;

const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const ToggleCircle = styled.span<{checked: boolean}>`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px; // Circle size
  height: 45px; // Circle size
  border-radius: 9999px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); // subtle shadow to the circle
  transform: ${props => props.checked ? 'translateX(39px)' : 'translateX(100)'};
`;

const BillingToggle: React.FC<{isAnnualBilling: boolean, setIsAnnualBilling: (arg: boolean) => void}> =
    ({isAnnualBilling, setIsAnnualBilling} : {isAnnualBilling: boolean, setIsAnnualBilling: (arg: boolean) => void}) => {

    const toggleBilling = () => {
        const newBillingType = isAnnualBilling ? 'monthly' : 'annually';
        setIsAnnualBilling(!isAnnualBilling);
    };

    return (
        <div className="flex items-center mb-12 justify-center my-4">
            <span className="text-lg font-large text-white mr-3">Billed Monthly</span>
            <ToggleLabel htmlFor="toggle-checkbox">
                <ToggleInput
                    id="toggle-checkbox"
                    type="checkbox"
                    onChange={toggleBilling}
                    checked={isAnnualBilling}
                />
                <ToggleCircle checked={isAnnualBilling} />
            </ToggleLabel>
            <span className="text-lg font-large text-white ml-3">Billed Annually</span>
        </div>
    );
};


const CheckItem = ({ text }: { text: string }) => (
    <li className="flex items-start">
        <div className="flex-shrink-0">
            <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path d="M5 13l4 4L19 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <p className="ml-3 text-base leading-6 font-medium text-gray-200">
            {text}
        </p>
    </li>
);

const PricingTierBox = ({ planId, title, price, description, features, cta }: { planId: string, title: string, price: string, description: string, features: string[], cta: string }) => {

    const [hasClickedPricingPlan, setHasClickedPricingPlan] = useState(false);

    const handleClickPricingPlan = (plan: string) => {
        console.log("Clicked pricing plan: ", plan);

        setHasClickedPricingPlan(true);
        window.location.href = `/api/select_pricing_plan/${planId}`;
    }

    return (
        <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-8 bg-purple-600">
                <div>
                    <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-purple-700">
                        {title}
                    </h3>
                </div>
                <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                    {price}
                    <span className="ml-1 text-2xl font-medium text-white">/mo</span>
                </div>
                <p className="mt-5 text-lg leading-6 text-white">{description}</p>
            </div>
            <div className="px-6 pt-6 pb-8 bg-backgroundCruip">
                <ul>
                    {features.map((feature) => (
                        <CheckItem text={feature}/>
                    ))}
                </ul>
                <div className="mt-6 rounded-md shadow">
                    <button
                        onClick={() => handleClickPricingPlan(title)}
                        disabled={hasClickedPricingPlan}
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                        {cta}
                    </button>
                </div>
            </div>
        </div>
    );
}

const PricingComponent: React.FC<{isAnnualBilling: boolean}> = ({isAnnualBilling} : {isAnnualBilling: boolean}) => {
    return (
        <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3 md:max-w-none">

            <div className="flex flex-col rounded-lg overflow-hidden">
            </div>

            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="px-6 py-8 bg-purple-600">
                    <div>
                        <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-purple-700">
                            Addendum.ai Pro
                        </h3>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                        $47
                        <span className="ml-1 text-2xl font-medium text-white">one time</span>
                    </div>
                    <p className="mt-5 text-lg leading-6 text-white">
                        All of Addendum.ai's powerful SEO features, for a one-time purchase.
                    </p>
                </div>
                <div className="px-6 pt-6 pb-8 bg-backgroundCruip">
                    <ul>
                        <CheckItem text="SEO optimized articles for every episode you publish"/>
                        <CheckItem text="Automatic article writing from your RSS feed"/>
                        <CheckItem text="Easiest way to start bringing in traffic from Google, utilizing all of your content!"/>
                    </ul>
                    <div className="mt-6 rounded-md flex items-center justify-center">
                        <button
                            onClick={() => {
                                window.location.href = `/api/select_pricing_plan/one_time_purchase`;
                            }}
                            className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                            {"Get started"}
                        </button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default function Pricing({ session }: { session: Session}) {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);

    const [isAnnualBilling, setIsAnnualBilling] = useState(true);

    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-20 md:pb-20">

            <section className="relative">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-32 pb-12 md:pt-20 md:pb-20">
                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                            <h2 className="h2 mb-4">Strap in to power up your podcast marketing with AI.</h2>
                            <p className="text-xl text-gray-600">
                                Addendum.ai is a one-time purchase that will continue to bring in traffic
                                from Google for every new episode you publish.
                            </p>
                        </div>

                        <PricingComponent
                            isAnnualBilling={isAnnualBilling}
                            />

                    </div>
                </div>
            </section>

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}

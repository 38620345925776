import React from 'react';

export default function Faq() {
  return (
    <section>
      <div className="mt-24 max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800 border-b">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">

            <h1 id="faq" className="h2 mb-4">Frequently Asked</h1>
            <p className="text-xl text-gray-400">
            </p>
          </div>

          {/* Items */}
          <div className="grid gap-20">

            {/* 1st item */}
            <div className="md:grid items-center">
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto" data-aos="fade-right">
                <div className="">
                  <h3 className="h3 mb-3">Can this work for every podcast?</h3>
                  <p className="text-xl text-gray-400 mb-4">
                    Yes! Addendum.ai uses powerful methods to find the intersection of what people are searching for,
                    and what your podcast offers that makes sense in a textual medium. It works the best for podcasts
                    that contain a density of useful information, but helps for any type of podcast!
                  </p>
                </div>

                <div className="">
                  <h3 className="h3 mb-3">Can I customize the generated content?</h3>
                  <p className="text-xl text-gray-400 mb-4">
                  Yes! Addendum.ai generates content that you can preview, edit, and customize 
                  before publishing. Our tool serves as a starting point, ensuring you can add 
                  your personal touch to the content before it reaches your audience. That said, 
                  many of our users automatically publish the content we generate to various outlets
                  without any additional editing.
                  </p>
                </div>

                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Is my content safe and secure with Addendum.ai?</h3>
                  <p className="text-xl text-gray-400 mb-4">
                  Absolutely. We prioritize the privacy and security of your content. 
                  Addendum.ai uses secure protocols to ensure your podcasts and generated 
                  content are protected. Your content is yours alone, and we will never 
                  use it for any purpose other than to provide you with our services.
                  </p>
                </div>

                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Will Addendum.ai's posts look automated or inauthentic?</h3>
                  <p className="text-xl text-gray-400 mb-4">
                  Not at all. Our advanced AI is designed to generate human-like, relatable content 
                  that resonates with your audience. It's engineered to create posts that are not only 
                  SEO-rich but also engaging and authentic to your podcast's voice.
                  </p>
                </div>

                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <h3 className="h3 mb-3">Does Addendum.ai offer support and guidance on using the platform?</h3>
                  <p className="text-xl text-gray-400 mb-4">
                  Absolutely! The founders of Addendum.ai deeply believe in directly supporting users.
                  We're always available to answer questions, or even just to chat about your podcast. 
                  </p>
                </div>


              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  )
}

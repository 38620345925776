import React, {useState} from "react";
import {enqueueSnackbar} from "notistack";
import MDEditor from "@uiw/react-md-editor";

export default function PublishModal({article}: {article: Article}) {
    const [showModal, setShowModal] = useState(false);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState<string>("");  // State to store the base64-encoded image
    const [loading, setLoading] = useState(false);
    const [publishedArticleUrl, setPublishedArticleUrl] = useState<string>("");

    const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (reader.result && typeof reader.result === 'string') {
                    setImage(reader.result);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const publish = (article: Article) => {
        console.log("Publishing article: ", article);
        setLoading(true);
        fetch('/api/subdomain/create-post', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title: article.title,
                body: article.body,
                description: description,
                url: article.title.toLowerCase().replace(/ /g, "-"),
                image: image,
                date: "",
            }),
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                if (data.success) {
                    console.log('Success:', data);
                    enqueueSnackbar("Article published!", { variant: "success" });
                    setPublishedArticleUrl(data.url);
                } else {
                    console.error('Error:', data.error);
                    enqueueSnackbar("Error publishing article:" + data.message, { variant: "error" });
                }
            })
    }

    return (
        <>
            <button
                onClick={() => setShowModal(true)}
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
            >
                Publish to Subdomain
            </button>

            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-backgroundCruip outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 rounded-t">
                                    <div className="flex flex-col border-b border-solid border-gray-300">
                                        <p className="mt-2 text-gray-400">Confirm Publish</p>
                                        <p className="w-full leading-normal rounded-lg text-gray-100 mt-1 mb-2 text-2xl p-0 font-bold">
                                            { article.title }
                                        </p>
                                    </div>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                                          ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                {loading && (
                                    <div className="flex items-center justify-center p-6 flex-auto">
                                        <p className="text-gray-400 text-lg leading-relaxed">
                                            Publishing article...
                                        </p>
                                    </div>
                                )}
                                {publishedArticleUrl && (
                                    <div className="flex items-center justify-center p-6 flex-auto">
                                        <p className="text-gray-400 text-lg leading-relaxed">
                                            Article published! <a href={publishedArticleUrl} className="text-purple-600 hover:text-purple-700">View article</a>
                                        </p>
                                    </div>
                                )}
                                {!loading && !publishedArticleUrl && ( <>
                                <div className="relative p-6 flex-auto">
                                    <p className="mt-4 text-gray-400 text-lg leading-relaxed">
                                        Enter a description for the article:
                                    </p>
                                    <textarea
                                        className="w-full leading-normal rounded-lg text-gray-100 mb-2 text-2xl p-0 font-bold bg-gray-800 border-b-0 border-t-0 border-r-0 border-l-0 border-gray-900 focus:border-transparent focus:ring-0 focus:outline-none"
                                        rows={2}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                    <p className="my-4 text-gray-400 text-lg leading-relaxed">
                                        Upload an image for the article header:
                                    </p>
                                    <div className="relative flex-auto">
                                        <input
                                            type="file"
                                            id="file-upload"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }} // Hide the actual input
                                        />
                                        <label
                                            htmlFor="file-upload"
                                            className="cursor-pointer py-2 px-4 rounded inline-block bg-purple-600 hover:bg-purple-700 text-white font-bold uppercase text-sm outline-none focus:outline-none mr-1 mb-1"
                                        >
                                            Upload Image
                                        </label>
                                        {image && (
                                            <img src={image} alt="Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                        )}
                                    </div>
                                    <div className="my-5"
                                         data-color-mode="dark"
                                         style={{ maxHeight: '500px', overflowY: 'scroll' }}

                                    >
                                        <MDEditor.Markdown source={article.body} />
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        style={{ transition: "all .15s ease" }}
                                        onClick={() => setShowModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-purple-600 hover:bg-purple-700 text-white font-bold uppercase px-6 py-3 text-sm outline-none focus:outline-none mr-1 mb-1"
                                        type="button"
                                        style={{
                                            transition: "all .15s ease",
                                        }}
                                        onClick={() => { publish(article) }}
                                    >
                                        Publish
                                    </button>
                                </div>
                                </> )}

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}
import { useEffect, useRef, useState } from 'react';
import MDEditor, { selectWord } from "@uiw/react-md-editor";

// From https://loading.io/css/
import "../additional-styles/loading-spinner-1.css";
import React from 'react';

type keywordTableRow = {
    search: string,
    competition: string,
    volume: string,
}

export function SEOKeywords(props: { episodeId: string }) {
  const isAlreadySubscribedRef = useRef<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [keywordTable, setKeywordTable] = useState<keywordTableRow[]>([]);

  const stream = () => {
    const endpoint = `/api/subscribe_seo_keywords/${props.episodeId}`
    const stream = new EventSource(endpoint);

    stream.onmessage = event => {
      const payload = JSON.parse(event.data);

      if (payload.event_type === "message") {
        console.log("Message received: ", payload.message);
      }

      if (payload.event_type === "final_output") {
        console.log("Final output received.");
        console.log("Final output: ", payload.message);

        var jsonStr = payload.message;
        if (jsonStr.startsWith("```json") && jsonStr.endsWith("```")) {
          // Remove the triple backticks and 'json' from the start and end
          jsonStr = jsonStr.substring(7, jsonStr.length - 3).trim();
        }

        console.log("About to parse JSON string: ", jsonStr);
        setKeywordTable(JSON.parse(jsonStr));
      }

      if (payload.event_type === "end_stream") {
        console.log("End stream recieved.");
        setIsLoading(false);
        stream.close();
      }
    };

    stream.onerror = error => {
      console.error('EventSource failed:', error);
      stream.close();
    };
  }

  useEffect(() => {
    if (isAlreadySubscribedRef.current) {
      return;
    }
    isAlreadySubscribedRef.current = true;
    stream();
  }, []);

  return (
    <div 
      className="mt-8 pb-6 max-w-lg md:max-w-4xl mx-auto overflow-hidden transition-transform duration-500 transform"
    >
    <div className="bg-purple-600 rounded-t-lg p-6 flex flex-row justify-evenly">
      <div className="max-w-[80%]">
        <h2 className="text-white text-2xl font-semibold truncate">Best ranking SEO keywords for this episode</h2>
      </div>
    </div>
      <div className="bg-gray-800 mb-6 rounded-b-lg p-6">
        {isLoading &&
            <div className="lds-ripple"><div></div><div></div></div>}
        {keywordTable.length > 0 && (
            <div className="grid grid-cols-3 gap-4">
              <h2 className="text-white-400 text-2xl font-semibold truncate">Search text</h2>
              <h2 className="text-white-400 text-2xl font-semibold truncate text-center">Competition</h2>
              <h2 className="text-white-400 text-2xl font-semibold truncate text-right">Monthly Volume</h2>

              {keywordTable.map((row, index) => (
                  <React.Fragment key={index}>
                    <h2 className="text-gray-400 text-lg truncate">{row.search}</h2>
                    <h2 className="text-gray-400 text-lg truncate text-center">{row.competition}</h2>
                    <h2 className="text-gray-400 text-lg truncate text-right">{row.volume}</h2>
                  </React.Fragment>
              ))}
            </div>
        )}
      </div>



  </div>
  );
}

import '../Landing.css';

import Header from '../components/./marketing/header'

import PageIllustration from '../components/marketing/page-illustration'
import Footer from '../components/./marketing/footer'
import { Session } from '../session';


import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

export default function AdminCreateBlogPost({ session }: { session: Session}) {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);


    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-8xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-20">

            {/* Page header */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                <h1 className="h1">Create Blog Post</h1>
                <h2 className="h2 text-slate-500 pt-4">Direct upload</h2>
            </div>

            {/* Blog post creation form. Title, description, image url, body */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                <form action="/api/blog/create-post" method="post" encType="multipart/form-data">
                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <div>
                            <label htmlFor="title" className="block text-sm font-medium text-gray-300">
                                Title
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="title"
                                    id="title"
                                    className="shadow-sm focus:ring-slate-500 focus:border-slate-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-800 text-gray-300"
                                    placeholder="My Blog Post Title"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="description" className="block text-sm font-medium text-gray-300">
                                Description
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="description"
                                    id="description"
                                    className="shadow-sm focus:ring-slate-500 focus:border-slate-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-800 text-gray-300"
                                    placeholder="My blog post description."
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="date" className="block text-sm font-medium text-gray-300">
                                Date
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="date"
                                    id="date"
                                    className="shadow-sm focus:ring-slate-500 focus:border-slate-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-800 text-gray-300"
                                    placeholder="Date"
                                />
                            </div>
                        </div>

                        <div>
                            <label htmlFor="image" className="block text-sm font-medium text-gray-300">
                                Image URL
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    name="image"
                                    id="image"
                                    className="shadow-sm focus:ring-slate-500 focus:border-slate-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-800 text-gray-300"
                                    placeholder="Image url"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-2">
                            <label htmlFor="body" className="block text-sm font-medium text-gray-300">
                                Body
                            </label>
                            <div className="mt-1">
                                <textarea
                                    id="body"
                                    name="body"
                                    rows={10}
                                    className="shadow-sm focus:ring-slate-500 focus:border-slate-500 block w-full sm:text"
                                    placeholder="My blog post body."
                                />
                            </div>
                        </div>

                        {/* button for submit */}
                        <div className="sm:col-span-2">
                            <button
                                type="submit"
                                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-slate-500 hover:bg-slate-600"
                            >
                                Create Blog Post
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}

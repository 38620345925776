import './Landing.css';

import Header from './components/./marketing/header'

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';


import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
import ClaimSubdomain from "./components/claim-subdomain";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);


export default function Account({ session }: { session: Session}) {

    const [account, setAccount] = useState<any>({
        subscribed: false,
        plan: "not subscribed_noplan",
        planrenews: "Never"
    });

    useEffect(() => {
        fetch('/api/account')
        .then(response => response.json())
        .then(json => {
          console.log(json);
          setAccount(json);
          console.log("Done fetching account.");
        });
      }, []);

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);

    const unsubscribe = () => {
        fetch('/api/unsubscribe', { method: 'POST' })
        .then(response => response.json())
        .then(json => {
          console.log(json);
          setAccount(json);
          console.log("Done unsubscribing.");
        });
    }

    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-20">

            <section className="relative">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="pt-32 md:pt-20">
                        <div className="max-w-3xl mx-auto text-center pb-4 md:pb-8">
                            <h2 className="h2 mb-16">Account</h2>

                            <ClaimSubdomain />

                            <p className="mt-16 text-xl">Name: {session.name}</p>
                            <p className="text-xl">Email: {session.email}</p>
                            <p className="text-xl">Subscription status: {account.subscribed ? "You are subscribed!" : "Not currently subscribed."}</p>
                            {account.subscribed && account.plan !== "one_time_purchase" && (
                                <><p className="text-xl">Subscribed to plan: {account.plan.split('_')[0]} ({account.plan.split('_')[1]})</p><p
                                    className="text-xl">Subscription Renews: {account.planrenews}</p></>
                            )}

                            {account.subscribed && (
                            <button
                                className="mt-64 btn text-gray-500 hover:text-red-700 rounded-lg border-2 border-purple-600 border-opacity-25 hover:bg-purple-600"
                                onClick={unsubscribe}
                            >
                                Cancel Subscription
                            </button>
                            )}
                        </div>



                    </div>
                </div>
            </section>

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}

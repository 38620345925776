import { useEffect, useRef, useState } from 'react';

// From https://loading.io/css/
import "../additional-styles/loading-spinner-1.css";

import "../additional-styles/right-arrow-1.css";

export type Episode = {
  id: string;
  key: string;

  title: string;
  audioFilename: string;

  uploadDate: string;
  isProcessed: boolean;

  transcriptionComplete: boolean;
  transcription: string;

  summaryComplete: boolean;
  summary: string;

  tocComplete: boolean;
  tableOfContents: string;

  articlesGenerated: number;
  articles: any[];

  subscriptionTopic?: string;
};

function EpisodeSelectionCard({ episode }: { episode: Episode }) {
  // Placeholder function to navigate to the episode editor.
  // You would replace this with your actual navigation function.
  const goToEpisodeEditor = () => {
    console.log("Navigating to episode editor...");
    window.location.href = `/episode-workspace?episodeId=${episode.id}`;
  };

  const [progressUpdates, setProgressUpdates] = useState<string[]>(["Your episode is being processed..."]);

  const isSubscribedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!episode.subscriptionTopic || isSubscribedRef.current) {
      return;
    }
    // Call backend endpoint to subscribe to the topic
    // This will let us wait until the episode is processed,
    // then immediately update the field once it is ready.

    isSubscribedRef.current = true;
    const stream = new EventSource(`/api/subscribe/${episode.subscriptionTopic}`);

    stream.onmessage = event => {
      const payload = JSON.parse(event.data);

      if (payload.event_type === "message") {
        console.log("Message received: ", payload.message);
        setProgressUpdates(prevProgressUpdates => [...prevProgressUpdates, payload.message]);
      }

      if (payload.event_type === "end_stream") {
        console.log("End stream recieved.");
        stream.close();
      }
    };

    stream.onerror = error => {
      console.error('EventSource failed:', error);
      stream.close();
    };
  }, []);

  return (
    <div 
      className="mt-12 pb-10  max-w-lg md:max-w-3xl mx-auto rounded-lg overflow-hidden transition-transform duration-500 transform hover:scale-[1.10]"
      onClick={goToEpisodeEditor}
    >
    <div className="bg-purple-600 p-4 flex flex-row justify-between">
      <div className="max-w-[80%]">
        <p className="text-gray-400 pt-4 text-xs">Uploaded {episode.uploadDate}</p>
        <h2 className="text-white text-2xl font-semibold truncate">{episode.title}</h2>
      </div>
        {episode.isProcessed ?
           <div className="flex unshift-right">
           <div className="arrow arrow-first"></div>
           <div className="arrow arrow-second"></div>
           <div className="arrow arrow-third"></div>
         </div> 
         :
           <div className="lds-ripple"><div></div><div></div></div> 
        }
      </div>

    {/* Middle Pane: Episode summary or loading message */}
    <div className="p-4 bg-backgroundCruip text-gray-400 rounded-b-lg">
      {episode.isProcessed ? episode.summary.substring(0, 1000) + (episode.summary.length > 1000 ? '...' : '') : progressUpdates.map((update, index) => (
        <p key={index}>{ update }</p>
      ))  
      }
    </div>


      {/* Bottom Pane: Episode details */}
      <div className="px-4 py-1 grid grid-cols-2 md:grid-cols-4 gap-4 text-gray-600 justify-between">
      <div className="flex flex-col items-center">
          <strong>Transcription</strong>
          <p>{episode.transcriptionComplete ? "Complete" : "Processing..."}</p>
        </div>
        <div className="flex flex-col items-center">
          <strong>Summary</strong>
          <p>{episode.summaryComplete ? "Complete" : "Processing..."}</p>
        </div>
        <div className="flex flex-col items-center">
          <strong>Table of Contents</strong>
          <p>{episode.tocComplete ? "Complete" : "Processing..."}</p>
        </div>
      </div>

  </div>
  );
}

export default EpisodeSelectionCard;

import '../Landing.css';

import Header from '../components/./marketing/header'

import PageIllustration from '../components/marketing/page-illustration'
import Footer from '../components/./marketing/footer'
import { Session } from '../session';


import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

export default function AdminCreateBlogPost({ session }: { session: Session}) {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);

    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        fetch('/api/admin/users')
        .then(response => response.json())
        .then(json => {
          console.log(json);
          setUsers(json);
          console.log("Done fetching users.");
        });
      }, []);

    const loginAsUser = (email: string) => {
        window.location.href = `/api/admin/login-as-user/${email}`;
        /*fetch(`/api/admin/login-as-user/${email}`, {
            method: 'GET',
        })
        .then(response => response.json())
        .then(json => {
          console.log(json);
          window.location.href = "/";
        });*/
    }

    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-8xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-20">

            {/* Page header */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                <h1 className="h1">Login as any user</h1>
                <h2 className="h2 text-slate-500 pt-4">All users listed below.</h2>
            </div>

            <div className="max-w-5xl mx-auto pb-16 pt-10">
                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                        <div>
                            <label htmlFor="title" className="block text-sm font-medium text-gray-300">
                                User
                            </label>
                            <div className="mt-1">
                                <select
                                    name="user"
                                    id="user"
                                    className="shadow-sm focus:ring-slate-500 focus:border-slate-500 block w-full sm:text-sm border-gray-300 rounded-md bg-gray-800 text-gray-300"
                                >
                                    {users.map((user, index) => (
                                        <option key={index} value={user.email}>{user.name} - {user.email} - {user.subdomain}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6">
                        <button
                            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-slate-500 hover:bg-slate-600"
                            onClick={() => loginAsUser((document.getElementById("user") as HTMLInputElement).value)}
                        >
                            Login as this user
                        </button>
                    </div>
            </div>

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}

import './Landing.css';

import Header from './components/./marketing/header'

import Hero from './components/./marketing/hero'
import Features from './components/./marketing/features'
import Newsletter from './components/./marketing/newsletter'
import Testimonials from './components/marketing/testimonials'
import CallToAction from './components/./marketing/call-to-action';
import Faq from './components/./marketing/faq';

import SignIn from './components/signin';

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';
import {ScrollHandler} from "./ScrollHandler";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import ReactGA from 'react-ga4';
import Zigzag from "./components/marketing/zigzag";
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);


export default function Landing(
      { session } : { session: Session }
    ) {

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({page: location.pathname + location.search});
  }, [location]);

  return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
      <ScrollHandler />
      <Header 
        username={session.name}
        />

      <PageIllustration />

      <Hero />
      <Features />
      <CallToAction />

      <Zigzag />
      
      <Testimonials />
      <Newsletter />

      <SignIn />

      <Faq />

      <Footer />
    </div>
  </div>
    );
}

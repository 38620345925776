import MobileMenu from './mobile-menu';
import { logout } from '../../session';

export default function Header({ username }: { username : string}) {
  return (
    <header className="absolute w-full z-30">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Site branding */}
          <div className="shrink-0 mr-4">
            {/* Logo */}
            <a href="/" className="block" aria-label="Addendum.ai">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 12C2 10.1308 2 9.19615 2.40192 8.5C2.66523 8.04394 3.04394 7.66523 3.5 7.40192C4.19615 7 5.13077 7 7 7H17C18.8692 7 19.8038 7 20.5 7.40192C20.9561 7.66523 21.3348 8.04394 21.5981 8.5C22 9.19615 22 10.1308 22 12C22 13.8692 22 14.8038 21.5981 15.5C21.3348 15.9561 20.9561 16.3348 20.5 16.5981C19.8038 17 18.8692 17 17 17H7C5.13077 17 4.19615 17 3.5 16.5981C3.04394 16.3348 2.66523 15.9561 2.40192 15.5C2 14.8038 2 13.8692 2 12Z" stroke="white" strokeWidth="2"/>
<path d="M19 4C19 2.89543 18.1046 2 17 2H7C5.89543 2 5 2.89543 5 4" stroke="white" strokeWidth="2"/>
<path d="M19 20C19 21.1046 18.1046 22 17 22H7C5.89543 22 5 21.1046 5 20" stroke="white" strokeWidth="2"/>
<rect x="5.5" y="10.5" width="5" height="1" rx="0.5" stroke="white"/>
</svg>
            
            </a>
          </div>
          <a href="/" className="block md:grow" aria-label="Addendum.ai">
          <h1 className="font-extrabold text-2xl"> Addendum.ai </h1>
          </a>


          <div className="hidden md:flex">
              <a href="/episodes" className="font-medium text-gray-200 hover:text-purple-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">
                  Episodes
              </a>
            <a href="/#features-section" className="font-medium text-gray-200 hover:text-purple-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">
              Features
            </a>
            <a href="/pricing" className="font-medium text-gray-200 hover:text-purple-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">
              Pricing
            </a>
            <a href="/blog/index" className="font-medium text-gray-200 hover:text-purple-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">
              Blog
            </a>
            <a href="/#faq" className="font-medium text-gray-200 hover:text-purple-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">
              About
            </a>
            <a href="/contact" className="font-medium text-gray-200 hover:text-purple-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">
              Contact
            </a>
              {username === "Griffin" &&
              <a href="/admin/index" className="font-medium text-gray-200 hover:text-purple-600 px-4 py-3 flex items-center transition duration-150 ease-in-out">
                  Admin
              </a>}
          </div>


          {/* Desktop navigation */}
          <nav className="hidden md:flex md:grow">

            

            {/* Desktop sign in links */}
            <ul className="flex md:grow justify-end flex-wrap items-center">
              <li>
                <a
                  href={username ? "/account" : "/sign-in"}
                  className="font-medium text-purple-600 hover:text-gray-200 px-4 py-3 flex items-center transition duration-150 ease-in-out"
                >
                  { username ? username : "Sign in" }
                </a>
              </li>
              <li>
                { username ? 
                <a href="#" onClick={logout} className="btn-sm text-white hover:bg-purple-700 ml-3">
                Sign out
              </a>
                :
                <a href="/sign-up" className="btn-sm text-white bg-purple-600 hover:bg-purple-700 ml-3">
                  Sign up
                </a>
                }
              </li>
            </ul>
          </nav>

          <MobileMenu />

        </div>
      </div>
    </header>
  )
}

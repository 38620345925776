import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollHandler = () => {
    const location = useLocation();

    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash) {
                const element = document.getElementById(hash.substring(1));
                if (element) {
                    const elementRect = element.getBoundingClientRect();
                    const absoluteElementTop = elementRect.top + window.pageYOffset;
                    const offset = window.innerHeight / 4; // Adjust this value as needed
                    const aboveCenter = absoluteElementTop - (window.innerHeight / 2) + offset;
                    window.scrollTo({
                        top: aboveCenter,
                        behavior: 'smooth'
                    });
                }
            }
        };

        // Call once to handle the hash if it's already in the URL on component mount
        handleHashChange();

        // Listen for hash changes
        window.addEventListener('hashchange', handleHashChange, false);

        // Cleanup listener
        return () => {
            window.removeEventListener('hashchange', handleHashChange, false);
        };
    }, []); // Empty array ensures this effect is only run on mount and unmount

    return null; // This component does not render anything
};

import React, { useState } from 'react';
import "../additional-styles/loading-spinner-1.css";
import { Episode } from './episode-selection-card';
import { useSnackbar} from 'notistack';

type PodcastResult = {
    title: string,
    audio_url: string,
    description: string,
    date: string,
    podcast_title: string,
    podcast_description: string,
}

export default function UploadEpisodeViaRssCard({ setEpisodes } : { setEpisodes: React.Dispatch<React.SetStateAction<Episode[]>> }) {
  const [fileName, setFileName] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [podcastResults, setPodcastResults] = useState<PodcastResult[]>([]);

  const [rssFeedInput, setRssFeedInput] = useState("");

  const [lastUsedRssFeedUrl, setLastUsedRssFeedUrl] = useState("");

  const processEpisode = (podcastResult: PodcastResult) => {
      setLoading(true);

      fetch('/api/upload-via-rss', {
          headers: {
                'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
                rssFeedUrl: lastUsedRssFeedUrl,
                title: podcastResult.title,
                description: podcastResult.description,
                date: podcastResult.date,
                audioUrl: podcastResult.audio_url,
                podcastTitle: podcastResult.podcast_title,
                podcastDescription: podcastResult.podcast_description,
          })
      }).then(response => response.json())
          .then(json => {
              if (json.error) {
                  console.error('Error:', json.error);
                  enqueueSnackbar('Error uploading file: You are not signed in.', { variant: 'error' }); // Display error snackbar
                  setLoading(false);
                  return;
              }

              console.log("Done uploading file.");
              console.log(json);
              setLoading(false);

              const topic = json.topic;
              console.log("Adding loading episode...")
              setEpisodes((prevEpisodes: Episode[]) => [
                  {
                      id: json.topic,
                      key: "0",
                      title: podcastResult.title,
                      audioFilename: podcastResult.title,

                      uploadDate: "Uploading...",
                      isProcessed: false,

                      transcriptionComplete: false,
                      transcription: "",

                      summaryComplete: false,
                      summary: "",

                      tocComplete: false,
                      tableOfContents: "",

                      articlesGenerated: 0,
                      articles: [],

                      subscriptionTopic: topic,
                  },
                  ...prevEpisodes
              ]);
          })
          .catch((error) => {
              console.error('Error:', error);
              enqueueSnackbar('Error uploading file. Please try again.', { variant: 'error' }); // Display error snackbar
              setLoading(false);
          });
  }

  const getEpisodesFromRSSFeed = (rssFeedUrl: string) => {
    setLoading(true);
    fetch('/api/episodes-from-rss-feed', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ rssFeedUrl })
    }).then(response => response.json())
      .then(json => {
          if (json.error) {
              console.error('Error:', json.error);
              enqueueSnackbar('Error retrieving RSS feed.', { variant: 'error' }); // Display error snackbar
              setLoading(false);
              return;
          }

          console.log("Done fetching episodes.");
          console.log(json);
          setLoading(false);

          setPodcastResults(json);
          setLastUsedRssFeedUrl(rssFeedUrl);
      });
  }

  return (
    <div className="mt-12 max-w-3xl mx-auto shadow-md rounded-lg overflow-hidden">
        <div className="bg-purple-600 h-20 flex items-center justify-center">
          <h2 className="text-white text-lg font-semibold">Upload New Episode via RSS</h2>
        </div>
      <div className="p-6 bg-backgroundCruip">
          <p className="text-gray-400 mt-2">
              Enter the RSS feed URL of your podcast below to get started
          </p>
          <p className="text-gray-600">
          Not sure what your RSS feed URL is? Check out <a href="https://castos.com/tools/find-podcast-rss-feed/" target="_blank" rel="noreferrer" className="text-purple-600 hover:text-purple-700">this tool</a> to find it.
          </p>

          <div className="mt-4 flex justify-between items-center">
              <input type="text"
                     className="mt-4 text-gray-500 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                     placeholder="RSS Feed URL"
                     value={rssFeedInput}
                     onChange={(e) => setRssFeedInput(e.target.value)}
              />
              <button className="ml-2 mt-4 w-1/3 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-md px-4 py-2 transition-colors duration-200 transform"
                        onClick={() => getEpisodesFromRSSFeed(rssFeedInput)}
              >
                    Find Episodes
              </button>
          </div>

          {/* Show podcast results */}
          {loading && <div className="lds-ripple"><div></div><div></div></div>}

            {!loading && podcastResults.length > 0 && <>
                <div className="mt-8 max-h-[800px] overflow-y-auto">
                <ul className="mt-2">
                    {podcastResults.map((podcastResult: PodcastResult) => {
                    return <li key={podcastResult.audio_url} className="mt-4">
                        <p className="text-gray-400 ml-4">{podcastResult.date}</p>
                        <div className="rounded-3xl bg-gray-700 pl-4 pt-2 pb-2">
                            <h4 className="h4 text-purple-400">{podcastResult.title}</h4>
                            <p className="text-gray-400 mb-2 mt-2">{podcastResult.description}</p>
                            <div className="flex justify-between items-center">
                            <audio preload="none" src={podcastResult.audio_url} controls></audio>
                                <button className="mr-8 w-1/3 border-purple-500 border-2 hover:bg-purple-700 text-white font-semibold rounded-md px-4 py-2 transition-colors duration-200 transform"
                                        onClick={() => processEpisode(podcastResult)}
                                >
                                    Select this episode
                                </button>
                            </div>
                        </div>
                    </li>
                    })}
                </ul>
                </div>
            </>
            }


      </div>
    </div>
  );
}

import { useEffect, useRef, useState } from 'react';

// From https://loading.io/css/
import "../additional-styles/loading-spinner-1.css";


/*
    ExpandableDeliverable
    Displays a card for some type of content.
    The content isn't supplied in the prop, but is instead loading using the topic given.
    The topic is used to subscribe to a server-sent event stream. If the content is still being generated,
    the stream will send updates to the client. Once the content is ready, the stream will send the content
    and then close.
*/
function ExpandableDeliverable(props: { title: string, streaming_endpoint: string }) {
  const [progressUpdates, setProgressUpdates] = useState<string[]>([]);

  const isAlreadySubscribedRef = useRef<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const stream = (endpoint: string) => {
    const stream = new EventSource(endpoint);

    stream.onmessage = event => {
      const payload = JSON.parse(event.data);

      if (payload.event_type === "message") {
        console.log("Message received: ", payload.message);
        setProgressUpdates(prevProgressUpdates => [...prevProgressUpdates, payload.message]);
      }

      if (payload.event_type === "final_output") {
        console.log("Final output received.");
        setProgressUpdates(prevProgressUpdates => [...prevProgressUpdates, payload.message]);
      }

      if (payload.event_type === "stream_chunk") {
        // Add to the last element of prevProgressUpdates
        setProgressUpdates(prevProgressUpdates => {
          const lastElement = prevProgressUpdates[prevProgressUpdates.length - 1];
          return [...prevProgressUpdates.slice(0, prevProgressUpdates.length - 1), lastElement + payload.message]
        });
      }

      if (payload.event_type === "end_stream") {
        console.log("End stream recieved.");
        setIsLoading(false);
        stream.close();
      }
    };

    stream.onerror = error => {
      console.error('EventSource failed:', error);
      stream.close();
    };
  }

  useEffect(() => {
    if (isAlreadySubscribedRef.current) {
      return;
    }
    isAlreadySubscribedRef.current = true;
    stream(props.streaming_endpoint);
  }, []);

  const EXPANDABLE_DELIVERABLE_MAX_CHARS = 1000;

  return (
    <div
        onClick={() => setIsExpanded(prevState => !prevState)}
        className="mt-8 pb-6 max-w-lg md:max-w-4xl mx-auto rounded-lg overflow-hidden transition-transform duration-500 transform"
    >
    <div className="bg-purple-600 p-4 flex flex-row justify-between">
      <div className="max-w-[80%]">
        <h2 className="text-white text-2xl font-semibold truncate">{props.title}</h2>
      </div>
        {isLoading &&
           <div className="lds-ripple"><div></div><div></div></div> }
      </div>

      {/* Middle Pane: Episode summary or loading message */}
      <div className="max-h-[1200px] overflow-y-auto p-4 bg-backgroundCruip text-gray-400 rounded-b-lg">
        {progressUpdates.map((update, index) => (
            <>
              { update.split("\n").map((line, index2) => {
                // Calculate the current length of the displayed text
                const currentLength = progressUpdates
                    .slice(0, index)
                    .map(u => u.length)
                    .reduce((a, b) => a + b, 0) + line.length;

                // Check if the text should be displayed based on the isExpanded state and currentLength
                if (isExpanded || currentLength <= EXPANDABLE_DELIVERABLE_MAX_CHARS) {
                  return <p key={index + ":" + index2}>{line}</p>;
                } else if (index === 0 && index2 === 0) {
                  // If isExpanded is false and the text is longer than 1000 characters, truncate it
                  return <p key={index + ":" + index2}>{line.substring(0, EXPANDABLE_DELIVERABLE_MAX_CHARS)}...</p>;
                }
                return null; // Do not render lines that exceed the character limit when not expanded
              })}
            </>
        ))
        }
        <div className="text-right">
          <h2 className="text-gray-400 text-sm truncate">
            { isExpanded ? "Click to collapse" : "Click to expand" }</h2>
        </div>
      </div>

  </div>
  );
}

export default ExpandableDeliverable;

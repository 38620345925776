import './Landing.css';

import Header from './components/./marketing/header'

import PageIllustration from './components/marketing/page-illustration'
import Footer from './components/./marketing/footer'
import { Session } from './session';


import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from "react-router-dom";
import ReactGA from "react-ga4";
import MDEditor from '@uiw/react-md-editor';
const TRACKING_ID = "G-V8Y2M5GLY6";
ReactGA.initialize(TRACKING_ID);

type BlogPost = {
    title: string;
    description: string;
    url: string;
    image: string;
    date: string;
    body: string;
}

export default function BlogPost({ session }: { session: Session}) {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({page: location.pathname + location.search});
    }, [location]);


    const [blogPost, setBlogPost] = useState<BlogPost | null>(null);

    const { subdomain, id } = useParams();

    useEffect(() => {
        fetch(`/api/subdomain/${subdomain}/post/${id}`)
            .then(response => response.json())
            .then(data => {
                setBlogPost(data);
            });
    }, [id]);


    return (
  <div className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
    <div className="flex flex-col min-h-screen overflow-hidden">
        <Header
            username={session.name}
        />

        <PageIllustration />

<section className="relative">
    <div className="max-w-8xl mx-auto px-4 sm:px-6">
        <div className="pt-32 md:pt-20">

            {/* Page header */}
            <div className="max-w-5xl mx-auto pb-16 pt-10">
                <a href={`/podcast/${subdomain}/index`} ><p className="h1">{subdomain} Blog</p></a>
                <p className="h2 text-slate-500 pt-4">Learn more about {subdomain}</p>
            </div>

            {blogPost &&
            <div className="max-w-6xl mx-auto pb-6 md:pb-10">
                <div className="flex flex-col justify-center">
                    <div className="w-full">
                        <div className="w-full overflow-hidden">
                            <a href={blogPost.url}>
                                <img src={blogPost.image} alt={blogPost.description} className="w-full rounded-xl" style={{ maxHeight: '300px', objectFit: 'cover' }} />
                            </a>
                        </div>
                    </div>
                    <div className="mt-8 px-4">
                        <h1 className="h1 text-6xl pt-4 font-medium text-gray-300">
                            {blogPost.title}
                        </h1>
                        <h2 className="h4 text-3xl pt-4 text-gray-500 mt-4">
                            {blogPost.description}
                        </h2>
                        <div className="mt-2 text-sm text-gray-600">
                            <time dateTime="2020-03-16">{blogPost.date}</time>
                        </div>
                        <div className="container pt-8" data-color-mode="dark">
                            <MDEditor.Markdown source={blogPost.body} />
                        </div>

                    </div>
                </div>
            </div>
            }

        </div>
    </div>
</section>


      <Footer />

    </div>
  </div>
    );
}
